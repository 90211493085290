@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {
    .navigation-icon {
        @apply relative flex items-center justify-center
        h-12 w-12 mt-2 mb-2 mx-auto shadow-lg
        bg-gray-800 text-green-500
        hover:bg-green-600 hover:text-white
        rounded-3xl hover:rounded-xl
        transition-all duration-300 ease-linear
        cursor-pointer;
    }

    .navigation-tooltip {
        @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md
        text-white bg-gray-900 
        text-xs font-bold 
        transition-all duration-100 scale-0 origin-left;
    }

    .navigation-hr {
        @apply bg-gray-200 dark:bg-gray-800 
        border border-gray-200 dark:border-gray-800 rounded-full
        mx-2;
      }

    .profile {
        @apply fixed top-0 left-16 h-12 w-screen
        flex flex-row
        bg-gray-700 text-white
        overflow-hidden;
    }

    .profile-item {
        @apply relative flex items-center justify-center
        ml-2 mr-2 my-auto
    }

    .table-container {
        @apply fixed top-12 left-16 flex flex-col
        overflow-auto sm:-mx-6 lg:-mx-8 w-full h-full
        py-2 min-w-full sm:px-6 lg:px-8
    }

    .table-head {
        @apply text-sm font-bold text-gray-900 px-6 py-4 text-left
    }

    .table-rows {
        @apply bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100
    }

    .table-data-first {
        @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900
    }

    .table-data {
        @apply text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap
    }
}